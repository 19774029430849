html, body {
  height: 100%
}

body {
  font-family: $base_font;
  font-size: 100%;
  line-height: 1.5;
  letter-spacing: .60px;
  margin: 0;
  padding: 0;
  font-variant-ligatures: common-ligatures;
  font-feature-settings: "liga", "dlig" 0;
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: $text;
  background: $blue;
}

.hero {
  margin: 0;
  padding: 0;

  width: 100%;
  max-height: 100%;
  height: 100vh;
  overflow: hidden;

  video {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    object-fit: cover;
    z-index: 1;
    opacity: .7;
  }

  &:before {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    -webkit-filter: blur(10px);
    z-index: 2;
    background: $blue;
    opacity: .2;
  }

  & > .middle {
    position: relative;
    z-index: 5;
  }

  h1 {
    margin: 0;
    padding: 0;
    display: block;
    transform: translateX(-3%);
    position: absolute;
    top: 50px;
    left: 0;
    @media screen and (max-width: 767px) {
      top: 50px;
      left: 50%;
      transform: translateX(-53%);
    }
  }

  .content {
    display: flex;
    justify-content: center;
    flex-flow: column;
    width: 100%;
    height: 100vh;
    padding: 100px 0;
    @media screen and (max-width: 767px) {
      padding: 20px 0;
    }
    position: relative;
    z-index: 5;

    & > div {
      position: relative;
      z-index: 5;

      @media screen and (min-width: 768px) {
        margin-left: 100px;
      }

      @media screen and (max-width: 767px) {
        display: flex;
        align-items: center;
        justify-content: center;
        flex-flow: column;
      }

      h2 {
        margin: 0;
        @media screen and (max-width: 767px) {
          margin: 0 0 35px 0;
        }
        padding: 0;
        font-size: 45px;
        @media screen and (max-width: 767px) {
          font-size: 25px;
          text-align: center;
        }
        text-transform: uppercase;
        line-height: 1.1;
      }

      p {
        margin: 0 0 100px 0;
        padding: 0;
        font-size: 16px;
        font-family: $secondary_font;
        font-weight: 500;
        @media screen and (min-width: 768px) {
          br {
            display: none;
          }
        }
        @media screen and (max-width: 767px) {
          text-align: center;
          .slash {
            display: none;
          }
        }
      }

      a.button {
        color: white;
        background: $red;
        text-decoration: none;
        font-weight: 700;
        display: inline-block;
        border: 1px solid white;
        text-transform: uppercase;
        font-size: 15px;
        padding: 5px 20px;

      }
    }

    & > img {
      position: absolute;
      right: 100px;
      top: 50%;
      transform: translateY(-50%);
      aspect-ratio: 784 / 564;
      object-fit: cover;
      z-index: 1;
      opacity: .75;
      max-width: 700px;
      height: auto;

      @media screen and (max-width: 767px) {
        width: 100%;
        left: 5%;
        top: 50%;
        transform: translateY(-50%);
        height: 90vh;
        opacity: .3;
      }
    }
  }

  footer {
    position: absolute;
    bottom: 70px;
    @media screen and (max-width: 767px) {
      bottom: 50px;
    }
    left: 0;
    width: 100%;
    display: flex;
    justify-content: space-between;

    & > div {
      display: flex;

      @media screen and (max-width: 767px) {
        width: 100%;

        & span {
          display: none;
        }
      }

      & p {
        margin: auto 0 0 15px;
        font-size: 15px;

        @media screen and (max-width: 767px) {
          font-size: 10px;
          text-align: center;
          margin: 0 auto;
        }
      }
    }

    & > div + p {
      font-family: $secondary_font;
      font-size: 15px;
      font-weight: 500;
      margin: auto 0 0 0;

      @media screen and (max-width: 767px) {
        display: none;
      }

      & a {
        color: white;
        text-decoration: none;
        text-transform: uppercase;
        display: inline-block;
        border: 1px solid $green;
        padding: 9px 12px;

        &:first-of-type {
          margin-left: 10px;
        }
      }
    }
  }
}